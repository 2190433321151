/* CONCEPT COMPONENT */
.concept {
  text-align: left;
}
.restrictions {
  font-size: 14px;
}
.tab {
  display: inline-block;
  margin-left: 5%;
}
.menu-may-vary {
  margin: 50px auto 5px auto;
  font-size: 14px;
}
/* WINE COMPONENT */
.wine-category-title {
  margin-bottom: 0;
}
/* WINE SECTION COMPONENT*/
.wine-category {
  margin-top: 8%;
}
.wine-section > h2 {
  color: #b48b64;
}
/* WINE SUB-SECTION COMPONENT */
.wine-sub-section {
  width: 100%;
  border-spacing: 0 10px;
}
.wine-sub-section-title {
  color: #86090f;
}
.wine-sub-section-description {
  margin: 0 auto;
  margin-bottom: 5%;
  font-size: 14px;
}
/* WINE ROW COMPONENT */
.wine-row {
  text-align: left;
  font-size: 16px;
}
.wine-inventory-id {
  font-size: 12px;
  color: #86090f;
  width: 11%;
  padding-right: 2%;
}
.wine-name {
  width: 63%;
}
.wine-vintage {
  font-size: 12px;
  color: #86090f;
  width: 11%;
  padding-left: 2%;
}
.wine-price {
  text-align: right;
  width: 15%;
}
/* SHARED WINE-RELATED STYLING */
.wine-restrictions {
  font-size: 14px;
  margin-top: 5px;
}
/* COCKTAILS COMPONENT*/
.caipirinha {
}
.caipirinhas-pic {
  margin-bottom: 1rem;
}
.caipirinha > p > span {
  color: #86090f;
}
.caipirinha > h3 {
  color: #b48b64;
  font-size: 24px;
  margin: 2px 0;
}
.cachaca-pricing {
  margin: 0 auto 30px auto;
  color: #86090f;
}
/* COCKTAIL SECTION COMPONENT */
.cocktail-section > h1 {
  font-size: 32px;
  color: black;
  font-family: 'Oswald';
}
.cocktail-section-title {
  margin: 24px auto 5px auto;
  margin-top: 50px;
}
.cocktail-section-price {
  margin: 0 auto 20px auto;
  color: #86090f;
  font-size: 1.17rem;
}
/* COCKTAIL ITEM COMPONENT */
.cocktail-item {
}
.cocktail-item > h3 {
  margin: 0;
  color: #b48b64;
  font-size: 24px;
}
.cocktail-description {
  margin: 0 auto 10px auto;
}
.fortified-wine-description {
  color: #86090f;
}
.glass-or-bottle {
  margin: 20px 0 0 0;
  font-size: 14px;
}

/* FLIGHTS */
.flights-table {
  width: 100%;
  border-spacing: 0 15px;
}
.flights-description {
  margin-bottom: 5%;
  color: #86090f;
}
.flight-name {
  font-weight: 600;
  color: #b48b64;
}
.flight-description {
  font-size: 14px;
}
.flight-cals {
  font-size: 14px;
  margin: 0;
}
.flight-price {
  width: 25%;
  text-align: right;
}

/* FOODS */
.food-menu-title {
  margin-bottom: 10%;
}
.grilled-meats {
  margin-bottom: 5%;
}
.food-category {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-bottom: 5%;
  padding: 2% 1% 2% 1%;
}
.food-category-title {
  color: #b48b64;
  text-align: left;
  padding: 0 2%;
  margin: 0 0 20px 0;
}
.food-category-sub-title {
  font-size: 14px;
}
.food-list {
  text-align: center;
  margin: 2% auto;
  width: 80%;
}
.food-list-items {
  font-size: 16px;
  color: black;
}
.barrier {
  font-size: 20px;
  color: #86090f;
}
.food-dessert-price {
  align: right;
}
.salad-area-section {
  margin: 5% auto;
}
.salad-area-section-name {
  text-align: left;
  padding: 0 4%;
  color: #86090f;
  margin: 2% 0 2% 0;
  font-size: 20px;
}
.salad-area-sub-section-name {
  color: #b48b64;
  text-align: left;
  padding: 0 6%;
  margin: 0;
  font-size: 18px;
}
.meats-food-section {
  padding: 0 5% 5% 5%;
}
.meat-description {
  margin-bottom: 0;
}

/* DESSERT */
.dessert-container {
  margin-top: 0%;
}
.dessert-item {
  margin: 5% 0 5% 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}
.dessert-name {
  margin-bottom: 1%;
  color: #b48b64;
}
.dessert-description {
  margin: 0 auto 10px auto;
  padding: 0 10px;
}
.other-dessert-description {
  padding: 0 10px;
  padding-bottom: 20px;
}
.dessert-image {
  margin-bottom: 20px;
  width: 90%;
}
.dessert-sub-title {
  color: #86090f;
  margin: 8px 0 0 0;
}
.other-desserts {
  margin-top: 50px;
}

/* BAR APPETIZERS */
.bar-apps-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bar-item {
  margin-bottom: 5%;
}
.bar-app-name {
  margin: 0;
  color: #b48b64;
  line-height: 30px;
}
.bar-app-description {
  margin: 0;
  color: #86090f;
  line-height: 20px;
}
.bar-app-price {
  margin: 0;
}

/* Specials */
.specials-items {
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 5%;
}
.specials-title {
  margin: 0;
  margin-bottom: 2%;
  color: #b48b64;
}
.specials-description {
  margin: 0;
  color: #86090f;
  line-height: 20px;
  margin-bottom: 5%;
}
.specials-restrictions {
  margin: 0;
  font-size: 14px;
  width: 120%;
}
.box-menu-item {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  margin-bottom: 5%;
  padding: 2% 5% 2% 5%;
}

/* Specials */
.health-bullets {
  text-align: left;
  margin-bottom: 10px;
  padding-right: 20px;
  color: #86090f;
}
.health-bullets .health-text {
  color: black;
}
