@media screen and (min-width: 414px) {
  .App {
    margin: 0 auto;
    max-width: 650px;
  }
  .main {
    font-size: 20px;
  }
  /* container that wraps main sections */
  .home-main-container {
    width: 100%;
  }
  .content-main-container {
    width: 100%;
    margin-top: 0;
    font-size: 32px;
  } */
  .content-main-container {
    margin: 0 auto;
  }
  .caipirinha {
    width: 80%;
    margin: 0 auto;
  }
  .cocktail-section {
    width: 80%;
    margin: 0 auto;
  }
  .restrictions {
    font-size: 16px;
  }
  .content-header > h1 {
    font-size: 40px;
  }
  .main > h1 {
    font-size: 40px;
  }
  .main > h4 {
    font-size: 20px;
  }
  .location-selection > h3 {
    font-size: 24px;
  }
  .nearest-location > h3 {
    font-size: 24px;
  }
  .restrictions {
    margin: 0 auto;
    width: 80%;
  }
  .restrictions > p {
    font-size: 16px;
  }
  .cocktail-section > h1 {
    font-size: 40px;
  }
  .cocktail-section > h2 {
    font-size: 32px;
  }
  .wine-section > h2 {
    font-size: 32px;
  }
  .wine-sub-section-title {
    font-size: 24px;
  }
  .wine-sub-section {
    border-spacing: 0 20px;
  }
  .wine-name {
    font-size: 20px;
  }
  .wine-price {
    font-size: 20px;
  }
  .wine-vintage {
    font-size: 14px;
  }
  .wine-inventory-id {
    font-size: 14px;
  }
  /* FLIGHTS */
  .flight-name {
    font-weight: 600;
    font-size: 20px;
  }
  .flight-description {
    font-size: 18px;
  }
  .flight-cals {
    font-size: 18px;
    color: #86090F;
  }
  .flight-price {
    width: 25%;
    text-align: right;
    font-size: 20px;
  }
}
