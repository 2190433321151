body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}
/* GENERAL */
.margin0 {
  margin: 0;
}
.hide {
  display: none;
}
h1, h2, h3, h4, h5 {
  font-weight: normal;
  line-height: 35px;
  padding: 0;
}
a {
  text-decoration: none;
}
.line-break{
  border: solid 1px #86090F;
  margin: 5px;
}
/* header container */
.header {
  margin-bottom: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-header {
  margin-bottom: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
}
/* container that wraps main sections */
.home-main-container {
  width: 100%;
  /* background-color: #86090F; */
}
.content-main-container {
  width: 100%;
  font-size: 32px;
}
.content-header > h1 {
  color: #86090F;
  font-size: 24px;
  font-weight: 500;
  margin: 2% 0;
  font-family: 'Futura'
}
/* each of the main sections that gets routed to */
.main {
  margin: 5%;
  font-family: 'Futura';
  font-size: 16px;
}
.main > h1 {
  color: black;
  font-size: 32px;
  font-family: 'Oswald';
  margin-bottom: 10%;
}
.home-title {
  color: black;
  font-size: 26px;
  font-family: 'Oswald';
}
.home-title > span {
  color: #86090F;
}

/* HEADER */
.header-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 1vh 0 0 0;
}
.button {
  width: 40vw;
}
.logo {
  max-width:60%;
  min-width: 60%;
  height: auto;
}
.content-toggle{
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* LOADING */
.loading {
  margin-top: 25%;
}
.loading-text {
  font-size: 16px;
}
/* HOME */
.home-nav {
  /* display: flex; */
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: white;
}
